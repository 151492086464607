<template>
  <div v-if="recipe">
    <Modal ref="modal" @buttonClicked="validationModal($event)" />
    <div class="col mb-5">
      <div class="row align-items-center justify-content-between">
        <div class="col-sm col-12 order-2 order-sm-1">
          <div class="d-flex align-items-center justify-content-between">
            <div class="h1">
              {{ recipe.title }}
            </div>

            <Icon
              ref="heart"
              :name="like ? 'heartPlain' : 'heart'"
              size="large"
              class="ms-auto clickable"
              :class="{ 'text-danger animationHeart': like }"
              @click.native="likeRecipe()"
            />
            <div class="choices">
              <Icon
                name="settings"
                size="large"
                class="ms-3 clickable"
                @click.native="triggerSubmenu()"
              />
              <div class="choices-nav card p-2" :class="{ show: submenu }">
                <router-link class="mb-2" :to="{ path: recipe.id + '/edit' }">
                  Modifier
                </router-link>
                <a href="" @click.prevent="$refs.modal.open(recipe)">
                  Supprimer
                </a>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <div class="">
              <template v-if="recipe.time < 60">
                <span>{{
                  $moment.duration(recipe.time, "minutes").format("m [min]")
                }}</span>
              </template>
              <template v-else>
                <span>{{
                  $moment.duration(recipe.time, "minutes").format("h[h]mm")
                }}</span>
              </template>
            </div>

            <div class="mx-2">&#183;</div>

            <template v-if="recipe.categories.length > 0">
              <template>
                <div v-for="category in recipe.categories" :key="category">
                  {{ $t("category." + category) }}
                  <span class="px-2">&#183;</span>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col order-2 order-sm-1">
          <div class="h6 mb-5">
            {{ $t("common.instructions") }}
          </div>
          <div
            class="czeds-list"
            v-if="recipe.instructions && recipe.instructions.length > 0"
          >
            <div
              class="mb-5"
              v-for="(instruction, index) in recipe.instructions"
              :key="'Instruction-' + index"
            >
              <span class="mr-2">&#183;</span>
              {{ instruction }}
            </div>
          </div>
          <div v-else>
            Oh, looks like the creator of this recipe got lazy. <br />
            <router-link
              class="mt-2 d-block"
              :to="{ path: $route.params.id + '/edit/instructions' }"
            >
              Add instructions</router-link
            >
          </div>
        </div>
        <div class="col-md-4 col-12 order-1 order-sm-2">
          <div class="h6 mb-5">{{ $t("common.ingredients") }}</div>
          <Checklist v-if="recipe.ingredients" :list="recipe.ingredients" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import Button from "@/components/Button.vue";
  // import TileRecipe from '@/components/TileRecipe.vue'
  import Checklist from "@/components/Checklist.vue";
  import Modal from "@/components/Modal.vue";
  import Icon from "@/components/Icon.vue";

  import RecipeService from "@/services/RecipeService";

  export default {
    name: "SingleRecipe",
    components: {
      Icon,
      Checklist,
      Modal,
    },
    data() {
      return {
        similarRecipes: [],
        recipe: undefined,
        like: false,
        submenu: false,
      };
    },
    methods: {
      triggerSubmenu() {
        this.submenu = !this.submenu;
      },
      getImgUrl() {
        return (
          "https://loremflickr.com/240/840/yummy,food,recipe?random=" +
          Date.now()
        );
      },
      validationModal(button) {
        if (button.type === "primary") {
          this.deleteRecipe();
        }
      },
      likeRecipe() {
        this.like = !this.like;
      },
      deleteRecipe() {
        RecipeService.delete(this.recipe.id)
          .then(() => {
            this.$router.push({
              name: "Recipes",
              params: {
                id: this.$route.params.id,
                lang: this.$route.params.lang,
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    beforeRouteEnter(to, from, next) {
      RecipeService.get(to.params.id)
        .then((response) => {
          next((vm) => {
            vm.recipe = response.data;
          });
        })
        .catch((error) => {
          // TODO: Add alert
          console.log(error);
        });
    },
  };
</script>

<style lang="css" scoped></style>
