<template>
  <div
    class="container-fluid px-md-7 px-4 pb-7"
    :class="'transition-' + $store.state.transitionState"
    v-if="layout === undefined"
  >
    <Header />
    <router-view class="" />
  </div>
  <div v-else>
    <router-view class="" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";

export default {
  name: "Home",
  components: {
    Header,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  // beforeRouteUpdate(to, from, next) {
  //   console.log(to);
  //   console.log(from);
  //   next();
  // },
};
</script>
