<template lang="html">
  <div class="">
    <Modal ref="modal" @buttonClicked="validationModal($event)" />
    <div class="mb-3">
      {{ $t("recipe.form.edit.instructionListTitle") }}
    </div>
    <div
      v-for="(instruction, index) in recipe.instructions"
      class="mb-3 card d-flex align-items-center flex-row align-items-center"
      draggable="true"
      :class="{ 'border-primary': isFocus(index) }"
      :key="'instruction' + index"
    >
      <!-- <div class="col-auto">
        <Icon
          class="me-2 draggable"
          name="Menu"
          size="small"
          @click.native="removeInstruction(index)"
        />
      </div> -->
      <span
        role="textbox"
        tabindex="0"
        contenteditable
        class="col"
        @focus="focus = index"
        @blur="focus = -1"
        @input="(event) => changeInstruction(event, instruction)"
      >
        {{ instruction }}
      </span>
      <div class="col-auto">
        <!-- <Icon class="ms-2 clickable" name="arrowUp" size="small" />
        <Icon class="ms-2 clickable" name="arrowDown" size="small" /> -->
        <Icon
          class="ms-2 clickable"
          name="trash"
          size="small"
          @click.native="$refs.modal.open(index)"
        />
      </div>
    </div>
    <p v-show="recipe.instructions.length == 0" class="fs-6">
      <!-- TODO: add translation -->
      {{ $t("recipe.form.edit.noInstruction") }}
    </p>
    <hr class="bg-primary" v-if="recipe.instructions.length > 0" />
    <div class="card d-flex flex-row align-items-center">
      <span
        ref="newInstruction"
        role="textbox"
        class="col"
        tabindex="0"
        contenteditable
        data-placeholder="Next step"
      >
      </span>
      <div class="col-auto">
        <Icon
          class="ms-2 clickable"
          name="add"
          size="small"
          @click.native="addInstruction($refs.newInstruction.innerText)"
        />
      </div>
    </div>
    <div class="col-12 my-5 text-center">
      <Button
        @click="$emit('saved')"
        :label="$t('recipe.form.edit.buttonLabel')"
      />
      <a class="d-block mt-3" href="" @click.prevent="$emit('cancel')">{{
        $t("recipe.form.edit.buttonCancelLabel")
      }}</a>
    </div>
  </div>
</template>

<script>
  import Icon from "@/components/Icon.vue";
  import Modal from "@/components/Modal.vue";
  import Button from "@/components/Button.vue";

  export default {
    name: "Step3",
    components: {
      Icon,
      Button,
      Modal,
    },
    props: {
      recipe: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        focus: -1,
        newInstruction: null,
      };
    },
    methods: {
      isFocus(index) {
        return this.focus === index;
      },
      validationModal(event) {
        if (event.type === "primary") {
          this.removeInstruction(event.payload);
        }
        this.$refs.modal.close();
      },
      changeInstruction(event, index) {
        this.recipe.instructions[index] = event.target.innerText;
      },
      addInstruction(newInstruction) {
        this.recipe.instructions.push(newInstruction);
        this.$refs.newInstruction.innerText = "";
        this.$refs.newInstruction.focus();
      },
      removeInstruction(index) {
        this.recipe.instructions.splice(index, 1);
      },
    },
  };
</script>
