import axios from "axios";

let baseURL = "https://basilbees.com/api"; // Default base URL

if (process.env.NODE_ENV !== "production") {
  // If in development mode, use a proxy
  baseURL = "/api"; // Proxy path in development
}

export default axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
  },
});
