<template>
  <div class="czeds-navbar me-md-n7 me-n4">
    <!-- <div class="container-fluid"> -->
    <div class="d-flex align-items-center justify-content-between">
      <router-link class="czeds-menu-item" :to="{ name: 'Home', params: {} }">
        <Logo class="col-auto justify-self-start my-3"></Logo>
      </router-link>
      <div class="col-auto">
        <div
          class="czeds-menu d-flex align-items-center justify-content-between py-3 px-md-7 px-6 bg-yellow-pale"
        >
          <router-link
            class="czeds-menu-item"
            :to="{ name: 'WeeklyRecipe', params: {} }"
          >
            <Icon name="home" size="large" />
          </router-link>
          <router-link
            class="czeds-menu-item"
            :to="{ name: 'Recipes', params: {} }"
          >
            <Icon name="book" size="large" />
          </router-link>
          <router-link
            class="czeds-menu-item"
            :to="{ name: 'Profile', params: {} }"
          >
            <Icon name="user" size="large" class="" />
          </router-link>
        </div>
      </div>
      <!-- <div class="col-auto"> -->
      <!-- <div class="row justify-content-center czeds-menu"> -->
      <!-- <div class="col czeds-menu d-sm-block" ref="menu" :class="{'close': close}">
               <div class="row align-items-sm-center justify-content-center flex-column flex-sm-row"> -->
      <!-- <div class="col-auto align-self-end p-0 mb-5 clickable d-sm-none d-block" @click="triggerMenu()">
                  <Icon name="close" class="text-white"/>
                </div> -->
      <!-- <div class=""> -->
      <!-- <a
            v-if="$route.matched.length > 2"
            @click="$router.go(-1)"
            class="clickable czeds-menu-item col-auto"
            ><Icon name="arrowLeft" size="large" class=""
          /></a> -->
      <!-- </div> -->

      <!-- </div>
             </div> -->
      <!-- </div> -->
      <!-- </div> -->
      <!-- <div class="col-3 ms-auto">
        <div class="" v-show="displaySearch">
          <input
            type="text"
            name=""
            id="title"
            value=""
            class="form-control czeds-text-input"
            v-model="search"
            placeholder="Search"
          />
        </div>
      </div> -->
      <!-- <div class="czeds-menu ms-auto row justify-content-center align-items-baseline col-auto" > -->
      <!-- <a
          @click.prevent="triggerSearch()"
          href=""
          class="czeds-menu-item col-auto clickable"
        >
          <Icon name="search" size="large" class="" />
        </a> -->
      <!-- <a href="" class="czeds-menu-item col-auto clickable">
          <Icon name="user" size="large" class="" />
        </a> -->
      <!-- <LocaleChanger class="col-auto czeds-menu-item" /> -->
      <!-- </div> -->
      <!-- <div class="col-auto">
           <div class="row justify-content-center align-items-center">

          </div>
         </div> -->
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
  import Logo from "@/components/Logo.vue";
  // import LocaleChanger from "@/components/LocaleChanger.vue";
  import Icon from "@/components/Icon.vue";

  export default {
    name: "Header",
    components: {
      Icon,
      Logo,
      // LocaleChanger,
    },
    data() {
      return {
        close: true,
        search: "",
        displaySearch: false,
      };
    },
    methods: {
      triggerSearch() {
        this.displaySearch = !this.displaySearch;
      },
    },
    computed: {
      // isDeep() {
      //   return this.$route.matched.length > 2;
      // }
    },
    mounted() {
      // console.log(this.$route.matched.find(route => route.parent && route.parent.name !== "Home"));
      // console.log(this.$router.options.routes.find(route => {
      //   if(route.component && route.component.name == 'Home' && route.component){
      //
      //   }
      // }));
      // window.addEventListener('click', (e) => {
      //   if (!this.$refs.menu.contains(e.target)) {
      //     if (!this.close) {
      //       this.triggerMenu();
      //     }
      //   }
      // })
    },
    watch: {
      // '$route'() {
      //   if (!this.close) {
      //     this.triggerMenu();
      //   }
      // }
    },
  };
</script>
