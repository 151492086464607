<template lang="html">
  <router-view class="row align-items-stretch justify-content-start flex-wrap"/>
</template>

<script>
// import RecipesList from '@/components/RecipesList.vue'

export default {
  name: 'Recipes',
  components: {
    // RecipesList
  }

}
</script>

<style lang="css" scoped>
</style>
