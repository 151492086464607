<template lang="html">
  <div class="">
    <div class="col-12 align-items-center mb-5">
      <div class="row align-items-center justify-content-between">
        <!-- <span class="text-capitalize">{{ $t("recipeList.subtitle") }}</span> -->
        <div class="h1 col-auto">{{ $t("recipeList.title") }}</div>
        <div class="col-auto">
          <router-link class="" :to="{ path: 'new/edit' }">
            <Button :label="$t('recipeList.newRecipe')" size="small" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="row align-items-center justify-content-between">
      <div
        class="col-auto mb-3"
        v-for="(recipe, index) in currentPageList"
        :key="'recipe-' + index"
      >
        <TileRecipe :recipe="recipe" />
      </div>
    </div>
    <div class="col-3 mb-4" v-if="recipes.length == 0">No recipes yet</div>
    <div class="col-12 d-flex align-items-center justify-content-center">
      <Pagination
        class=""
        :total="recipes.length"
        :itemPerPage="itemPerPage"
        v-model="currentPage"
      />
    </div>
  </div>
</template>

<script>
  import TileRecipe from "@/components/TileRecipe.vue";
  import Pagination from "@/components/Pagination.vue";
  import Button from "@/components/Button.vue";
  import RecipeService from "@/services/RecipeService";

  export default {
    name: "RecipesList",
    components: {
      TileRecipe,
      Pagination,
      Button,
    },
    data() {
      return {
        currentPage: 0,
        itemPerPage: 20,
        recipes: [],
      };
    },
    computed: {
      currentPageList() {
        return [...this.recipes].splice(
          this.currentPage * this.itemPerPage,
          this.itemPerPage
        );
      },
    },
    beforeRouteEnter(to, from, next) {
      RecipeService.getAll()
        .then((response) => {
          next((vm) => {
            vm.recipes = response.data;
          });
        })
        .catch((error) => {
          // TODO: Add alert
          console.log(error);
        });
    },
  };
</script>
