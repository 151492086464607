<template>
  <div class="row" v-if="ingredients.length > 0">
    <Modal ref="modal" @buttonClicked="validationModal($event)" />
    <div class="col-12 mb-5">
      <Dropdown
        v-model="newIngredient"
        :options="availableIngredients"
        @selected="addIngredient($event)"
      >
        <template slot="label">
          {{ $t("recipe.form.edit.newIngredientLabel") }}
        </template>
      </Dropdown>

      <div class="col-12 mt-7">
        <div class="mb-3">
          {{ $t("recipe.form.edit.ingredientListTitle") }}
        </div>
        <transition-group
          v-show="recipe.ingredients.length > 0"
          class="list-group"
          name="ingredient-list"
          tag="div"
        >
          <div
            class="bg-black rounded-1 text-decoration-none d-block d-flex align-items-center justify-content-start p-3 mb-3"
            v-for="(ingredient, index) in recipe.ingredients"
            :key="'ingredient' + index"
          >
            <div
              class="czeds-TileRecipe--content col d-flex align-items-center justify-content-between flex-wrap"
            >
              <div class="czeds-TileRecipe--content-title col order-1">
                {{ ingredient.name }}
              </div>
              <Quantity
                :value="ingredient.quantity"
                @input="checkQuantity(ingredient, $event)"
                :unit="ingredient.unit"
                :min="0"
                class="col-sm-auto col-12 order-3 order-sm-2 me-sm-2 mt-2 mt-sm-0"
              />
              <div
                class="col-auto d-flex align-items-center order-2 order-sm-3"
              >
                <Icon
                  class="ms-2 clickable"
                  name="close"
                  size="small"
                  @click.native="$refs.modal.open(ingredient)"
                />
              </div>
            </div>
          </div>
        </transition-group>
        <p v-show="recipe.ingredients.length == 0" class="fs-6">
          <!-- TODO: add translation -->
          Aucun ingrédient ajouté pour cette recette
        </p>
      </div>
    </div>
    <div class="col-12 mb-5 text-center">
      <Button @click="sendForm()" :label="$t('recipe.form.edit.buttonLabel')" />
      <a class="d-block mt-3" href="" @click.prevent="$emit('cancel')">{{
        $t("recipe.form.edit.buttonCancelLabel")
      }}</a>
    </div>
  </div>
</template>

<script>
  import Icon from "@/components/Icon.vue";
  import Button from "@/components/Button.vue";
  import IngredientService from "@/services/IngredientService";
  import Dropdown from "@/components/Dropdown.vue";
  import Quantity from "@/components/Quantity.vue";
  import Modal from "@/components/Modal.vue";

  export default {
    name: "Step2",
    components: {
      Icon,
      Button,
      Quantity,
      Dropdown,
      Modal,
    },
    model: {
      prop: "recipe",
      event: "change",
    },
    props: {
      recipe: {
        type: Object,
        required: true,
      },
    },
    computed: {
      availableIngredients() {
        return this.ingredients.map((ingredient) => {
          return {
            name: ingredient,
            // id: ingredient.id,
            // enabled: ingredient.used,
            // unit: ingredient.unit,
            disabled: this.recipe.ingredients
              .map((ingredient) => ingredient.name)
              .includes(ingredient),
          };
        });
      },
    },
    data() {
      return {
        ingredients: [],
        newIngredient: "",
      };
    },
    methods: {
      sendForm() {
        this.$emit("saved");
      },

      addIngredient(ingredientToAdd) {
        let alreadyExists = this.recipe.ingredients
          .map((ingredient) => ingredient.name)
          .includes(ingredientToAdd);

        if (!alreadyExists) {
          this.recipe.ingredients.push({
            name: ingredientToAdd,
            quantity: 1,
            unit: "lbs",
          });
        }
        this.newIngredient = "";
      },
      removeIngredient(ingredientToRemove) {
        this.recipe.ingredients = this.recipe.ingredients.filter(
          (ingredient) => ingredient !== ingredientToRemove
        );
      },
      validationModal(event) {
        if (event.type == "primary") {
          this.removeIngredient(event.payload);
          this.$refs.modal.close();
        } else {
          this.$refs.modal.close();
        }
      },
      checkQuantity(ingredient, payload) {
        ingredient.quantity = payload;
        if (payload === 0) {
          this.$refs.modal.open(ingredient);
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      IngredientService.getAll()
        .then((response) => {
          next((vm) => {
            vm.ingredients = response.data;
          });
        })
        .catch((error) => {
          // TODO: Add alert
          console.log(error);
        });
    },
  };
</script>
<style lang="scss">
  .ingredient-list-enter-active,
  .ingredient-list-leave-active {
    transition: all 0.3s ease;
  }
  .ingredient-list-enter,
  .ingredient-list-leave-to {
    margin-left: -10px;
    opacity: 0;
  }
</style>
