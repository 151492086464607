<template lang="html">
  <div
    v-bind:is="component"
    class="czeds-logo"
    :class="'czeds-logo-' + this.size"
  ></div>
</template>

<script>
  import HorizontalLogo from "@/assets/svgs/TextLogo.svg";
  import VerticalLogo from "@/assets/svgs/VerticalLogo.svg";

  export default {
    name: "Logo",
    components: {
      HorizontalLogo,
      VerticalLogo,
    },
    props: {
      size: {
        type: String,
        required: false,
        default: "small",
      },
      horizontal: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    computed: {
      component() {
        return this.horizontal ? "HorizontalLogo" : "VerticalLogo";
      },
      // height(){
      // return this.size == 'small' ? '50px' : '74px';
      // }
    },
  };
</script>
