import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/main.scss";
import i18n from "./i18n";
import VueMoment from "vue-moment";
import momentDurationFormatSetup from "moment-duration-format";
import Meta from "vue-meta";

const moment = require("moment");
// moment.locale('en');
moment.updateLocale("en", {
  relativeTime: {
    h: "1 hour",
  },
  week: {
    dow: 1,
  },
});
moment.updateLocale("fr", {
  relativeTime: {
    h: "1 heure",
  },
  week: {
    dow: 1,
  },
});
momentDurationFormatSetup(moment);
Vue.prototype.moment = moment;
// Vue.use(moment)

Vue.config.productionTip = false;

Vue.use(VueMoment, {
  moment,
});
Vue.use(Meta);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
