var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn",class:[_vm.buttonClass, 'btn-' + _vm.size, 'position-relative'],attrs:{"type":"button","name":"button","disabled":_vm.disabled},on:{"click":function($event){{
      !_vm.loading ? _vm.$emit('click') : null;
    }}}},[_c('div',{class:[
      'loader',
      'position-absolute',
      _vm.loading ? 'visible' : 'invisible',
    ]},[_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"})]),_c('div',{class:{ invisible: _vm.loading }},[_vm._v(_vm._s(_vm.label))])])
}
var staticRenderFns = []

export { render, staticRenderFns }