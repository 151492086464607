import http from "../http-common";

class SelectionService {
  getWeekSelection(recipeDate) {
    return http.get(`/selections/week/${recipeDate}`);
  }
  create(weekNumber) {
    return http.post("/selections", weekNumber);
  }
  findAll() {
    return http.get("/selections");
  }
}

export default new SelectionService();
