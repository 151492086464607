import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import WeeklyRecipes from '../views/WeeklyRecipes.vue'
import WeeklyRecipe from "../views/WeeklyRecipe.vue";
import Recipes from "../views/Recipes.vue";
import SingleRecipe from "../views/SingleRecipe.vue";
import RecipesList from "../views/RecipesList.vue";
import EditRecipe from "../views/EditRecipe.vue";
import Step1 from "../views/Step1.vue";
import Step2 from "../views/Step2.vue";
import Step3 from "../views/Step3.vue";

import i18n from "../i18n";
import NProgress from "nprogress";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    beforeEnter(to, from, next) {
      if (to.path.startsWith("/public")) {
        next();
      } else {
        next("/" + i18n.fallbackLocale);
      }
    },
  },
  {
    path: "/:lang",
    name: "Home",
    component: Home,
    redirect: { name: "WeeklyRecipe" },
    beforeEnter(to, from, next) {
      const lang = to.params.lang;
      i18n.locale = lang;
      if (!i18n.availableLocales.includes(lang)) {
        return next("/" + i18n.fallbackLocale + to.path);
      }
      return next();
    },
    children: [
      {
        name: "Profile",
        path: "profile",
        component: () => import("../views/Profile.vue"),
      },
      {
        name: "ComingSoon",
        path: "coming-soon",
        meta: { layout: "LayoutAdmin" },
        component: () => import("../views/ComingSoon.vue"),
      },
      {
        path: "week/:weekNumber?",
        name: "WeeklyRecipe",
        component: WeeklyRecipe,
        beforeEnter(to, from, next) {
          const weekNumber = to.params.weekNumber;
          if (!weekNumber) {
            return next({
              name: "WeeklyRecipe",
              params: {
                lang: to.params.lang,
                weekNumber: Vue.moment().isoWeek(),
              },
            });
          }
          return next();
        },
      },
      {
        path: "recipes",
        name: "Recipes",
        redirect: { name: "RecipesList" },
        component: Recipes,
        children: [
          {
            path: "all",
            name: "RecipesList",
            component: RecipesList,
          },
          {
            path: ":id/edit",
            name: "NewRecipe",
            component: EditRecipe,
            redirect: { name: "GeneralInformations" },
            children: [
              {
                path: "informations",
                name: "GeneralInformations",
                component: Step1,
              },
              {
                path: "ingredients",
                name: "Ingredients",
                component: Step2,
                beforeEnter: (to, from, next) => {
                  if (to.params.id === "new") {
                    return next({
                      name: "GeneralInformations",
                      params: to.params,
                    });
                  } else {
                    next();
                  }
                },
              },
              {
                path: "instructions",
                name: "Instructions",
                component: Step3,
                beforeEnter: (to, from, next) => {
                  if (to.params.id === "new") {
                    return next({
                      name: "GeneralInformations",
                      params: to.params,
                    });
                  } else {
                    next();
                  }
                },
              },
            ],
          },
          {
            path: ":id",
            name: "SingleRecipe",
            component: SingleRecipe,
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
});
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.name !== "ComingSoon") {
    // If the user is trying to access any route other than 'ComingSoon',
    // redirect them to the 'ComingSoon' route with the preserved 'lang' parameter.
    next({ name: "ComingSoon", params: { lang: to.params.lang } });
  } else {
    // If the user is accessing the 'ComingSoon' route, proceed with the navigation.
    next();
  }
});
router.afterEach(() => {
  NProgress.done();
});
export default router;
