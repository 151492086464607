<template lang="html">
  <div class="czeds-pagination d-flex align-items-center justify-content-center">
    <Icon name="arrowLeft" :class="localCurrentPage <= 1 ? 'text-gray not-clickable':'clickable text-light' " class="czeds-pagination--item  me-2 p-0" @click.native="previousPage()"/>
    <div class="czeds-pagination--item mx-1" @click="changePage(n)" :class="{'active': localCurrentPage == n, 'clickable': localCurrentPage !== n}" v-for="n in totalPages" :key="'Pagination-'+n">{{n}}</div>
    <Icon name="arrowRight" class="czeds-pagination--item clickable ms-2 p-0" @click.native="nextPage()"/>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
export default {
  name: 'Pagination',
  components:{
    Icon
  },
  model: {
    prop: 'currentPage',
    event: 'change'
  },
  props: {
    currentPage:{
      type: Number,
      default: 0
    },
    itemPerPage: {
      type: Number,
      default: 12
    },
    total:{
      type: Number,
      required: true
    }
  },
  data(){
    return {
      localCurrentPage: this.currentPage +1
    }
  },
  computed: {
    totalPages(){
      return Math.ceil(this.total/this.itemPerPage);
    }
  },
  methods:{
    nextPage(){
      this.changePage(this.localCurrentPage+1);
    },
    previousPage(){
      this.changePage(this.localCurrentPage-1);
    },
    changePage(number){
      this.localCurrentPage = number;
      this.$emit('change', this.localCurrentPage-1);
    }
  }
}
</script>
