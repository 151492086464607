<template>
  <div class="custom-dropdown bg-white py-2 px-4 rounded">
    <div class="selected-option" @click="toggleDropdown">
      {{ selectedOption.name }}
      <Icon
        :name="isOpen ? 'arrowUp' : 'arrowDown'"
        class="clickable"
        size="small"
        @click.native="toggleDropdown"
      />
    </div>
    <div
      :class="{ open: isOpen }"
      class="position-absolute dropdown-options bg-white rounded py-2 px-4"
    >
      <div
        v-for="option in options"
        :key="option.value"
        @click="selectOption(option)"
        class="mb-2"
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from "@/components/Icon.vue";

  export default {
    components: {
      Icon,
    },
    props: {
      options: {
        type: Array,
        required: true,
      },
      value: Object, // Model value prop with an object structure (e.g., { name: '', value: '' })
    },
    data() {
      return {
        isOpen: false,
        selectedOption: this.value || { name: "", value: "" }, // Use the model value or default to an empty object
      };
    },
    watch: {
      value(newVal) {
        // Update selectedOption when the model value changes externally
        this.selectedOption = newVal;
      },
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      selectOption(option) {
        this.selectedOption = option;
        this.isOpen = false;
        this.$emit("input", option); // Emit the selected value to update the model
        this.$emit("option-selected", option); // Emit a custom event when an option is selected
      },
    },
  };
</script>
<style lang="scss">
  .custom-dropdown {
    cursor: pointer;
    position: relative;
    .dropdown-options {
      transform: translateY(-10px);
      visibility: hidden;
      max-height: 0;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      left: 0;
      right: 0;
      overflow: scroll;
      &.open {
        visibility: visible;
        max-height: 170px;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
</style>
