<template>
  <div class="px-2">
    <div class="col p-0">
      <div class="row mb-4">
        <div class="col-12">
          <div class="h1">
            {{ $t("recipe.form." + formType + ".pageTitle") }}
            <!-- {{ $t("recipe.form.steps." + steps[currentStep] + ".navTitle") }} -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <Subnav :steps="steps" :formType="formType" />
        </div>
        <!-- <div class="col"> -->
        <!-- Step {{ currentStep + 1 }} / {{ steps.length }}
          <div class="text-uppercase text-white fs-5 mt-1">
            {{ $t("recipe.form.steps." + $route.name + ".stepTitle") }}
          </div> -->
        <router-view
          class="col"
          @saved="nextStep()"
          @cancel="handleCancel()"
          :formType="formType"
          :recipe="recipe"
        />
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
  import Subnav from "@/components/Subnav.vue";
  import RecipeService from "@/services/RecipeService";
  export default {
    name: "EditRecipe",
    components: {
      Subnav,
    },
    data() {
      return {
        steps: ["GeneralInformations", "Ingredients", "Instructions"],
        recipe: {
          id: null,
          title: "",
          time: 35,
          ingredients: [],
          categories: [],
        },
      };
    },
    computed: {
      formType() {
        if (this.$route.params.id !== "new") {
          return "edit";
        } else {
          return "create";
        }
      },
      currentStep() {
        return this.steps.findIndex((step) => step == this.$route.name);
      },
    },
    mounted() {
      if (this.formType == "edit") {
        this.getRecipe(this.$route.params.id);
      }
    },

    methods: {
      handleCancel() {
        if (this.formType == "edit") {
          this.$router.push({
            name: "SingleRecipe",
            params: {
              id: this.$route.params.id,
              lang: this.$route.params.lang,
            },
          });
        } else {
          this.$router.push({
            name: "Recipes",
            params: {
              lang: this.$route.params.lang,
            },
          });
        }
      },
      nextStep() {
        if (this.currentStep > 0 || this.formType == "edit") {
          this.update();
        } else {
          this.create();
        }
      },
      getRecipe(id) {
        RecipeService.get(id)
          .then((response) => {
            this.recipe = response.data;
          })
          .catch((error) => {
            // TODO: Add alert
            console.log(error);
          });
      },

      create() {
        RecipeService.create(this.recipe)
          .then((response) => {
            this.recipe = response.data;
            this.$router.push({
              name: this.steps[this.currentStep + 1],
              params: { id: response.data.id, lang: this.$route.params.lang },
            });
          })
          .catch((error) => {
            // TODO: Add alert
            console.log(error);
          });
      },
      update() {
        RecipeService.update(this.recipe.id, this.recipe).then(() => {
          this.$router.push({
            name: this.steps[this.currentStep + 1],
            params: {
              id: this.$route.params.id,
              lang: this.$route.params.lang,
            },
          });
        });
      },
    },
  };
</script>
