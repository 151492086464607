<template lang="html">
  <!-- DO NOT REMOVE COMMENT -->
  <!-- <div> -->
  <!-- <span  v-html="require('@/assets/svgs/icons/'+name+'.svg')"></span> -->
  <!-- </div> -->
  <div
    :is="name"
    :class="['czeds-icon', type === 'solid' ? 'fill' : 'stroke']"
    :height="height"
  ></div>
</template>

<script>
  import Eye from "@/assets/svgs/icons/eye.svg";
  import Home from "@/assets/svgs/icons/home.svg";
  import Book from "@/assets/svgs/icons/book.svg";
  import User from "@/assets/svgs/icons/user.svg";
  import ArrowLeft from "@/assets/svgs/icons/arrow-left.svg";
  import ArrowRight from "@/assets/svgs/icons/arrow-right.svg";
  import ArrowUp from "@/assets/svgs/icons/arrow-up.svg";
  import ArrowDown from "@/assets/svgs/icons/arrow-down.svg";
  import Menu from "@/assets/svgs/icons/menu.svg";
  import Close from "@/assets/svgs/icons/close.svg";
  import Heart from "@/assets/svgs/icons/heart.svg";
  import HeartPlain from "@/assets/svgs/icons/heartPlain.svg";
  import Search from "@/assets/svgs/icons/search.svg";
  import Settings from "@/assets/svgs/icons/settings.svg";
  import Edit from "@/assets/svgs/icons/edit.svg";
  import Trash from "@/assets/svgs/icons/trash.svg";
  import Add from "@/assets/svgs/icons/add.svg";
  import Minus from "@/assets/svgs/icons/minus.svg";

  export default {
    name: "Icon",
    components: {
      Eye,
      Home,
      Book,
      User,
      ArrowUp,
      ArrowDown,
      ArrowLeft,
      ArrowRight,
      Menu,
      Close,
      Search,
      Heart,
      HeartPlain,
      Settings,
      Edit,
      Trash,
      Add,
      Minus,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        required: false,
        default: "small",
      },
      type: {
        type: String,
        required: false,
        default: "solid",
      },
    },
    computed: {
      height() {
        if (this.size == "small") {
          return "18px";
        } else if (this.size == "large") {
          return "26px";
        } else {
          return "50px";
        }
      },
    },
  };
</script>
