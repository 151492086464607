<template>
  <div class="czeds-subnav d-flex">
    <div class="mb-6 col" v-for="step in steps" :key="step">
      <router-link
        class="d-flex flex-wrap align-items-center justify-content-center czeds-subnav-item text-decoration-none"
        :to="{ name: step, params: {} }"
        :class="{ disabled: isDisabled(step) }"
      >
        <div class="text-center col-12 mb-2">
          <div class="h6">
            {{ $t("recipe.form.steps." + step + ".navTitle") }}
          </div>
          <!-- <span class="fs-6 text-gray">{{
            $t("recipe.form.steps." + step + ".navSubtitle")
          }}</span> -->
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Subnav",
    props: {
      steps: {
        type: Array,
        required: true,
      },
      formType: {
        type: String,
        required: true,
        default: "create",
      },
    },
    methods: {
      isDisabled(step) {
        return this.formType === "create" && step !== "GeneralInformations";
      },
    },
    // data() {
    //   return {
    //     steps: ["GeneralInformations", "Ingredients", "Instructions"],
    //   };
    // },
  };
</script>
