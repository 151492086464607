<template>
  <div>
    <label for="title" class="form-label">
      <slot name="label" />
    </label>
    <div
      @focusout="closeDropdown()"
      tabindex="0"
      class="czeds-dropdown"
      :class="{ show: open }"
      @focus="openDropdown()"
    >
      <div class="d-flex align-items-center justify-content-between">
        <input
          type="text"
          name=""
          id="title"
          value=""
          class=""
          @focus="openDropdown()"
          v-model="selected"
          @keydown.enter="!found ? select(selected) : false"
          placeholder="Oeuf, farine, ..."
        />
        <Icon
          :name="open ? 'arrowUp' : 'arrowDown'"
          class="clickable"
          size="small"
          @click.native="toggleDropdown()"
        />
      </div>
      <div class="czeds-dropdown--options">
        <div
          class="p-3 czeds-dropdown--options-item"
          v-if="selected !== '' && !found"
          @click="select(selected)"
        >
          <div class="fs-6">Create new ingredient :</div>
          {{ selected }}
        </div>
        <div
          class="p-3 czeds-dropdown--options-item"
          :class="{ disabled: option.disabled }"
          @click="!option.disabled ? select(option) : false"
          v-for="(option, index) in filteredOptions"
          :key="'option' + index"
        >
          {{ option.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Icon from "@/components/Icon.vue";

  export default {
    name: "Dropdown",
    components: {
      Icon,
    },
    model: {
      prop: "value",
      event: "input",
    },
    props: {
      value: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        selected: this.value,
        open: false,
        new: false,
      };
    },
    computed: {
      filteredOptions() {
        return this.options.filter((option) =>
          option.name
            .toLowerCase()
            .normalize("NFD")
            .includes(this.selected.toLowerCase().normalize("NFD"))
        );
      },
      found() {
        return this.options.findIndex(
          (option) =>
            option.name.toLowerCase().normalize("NFD") ==
            this.selected.toLowerCase().normalize("NFD")
        ) == -1
          ? false
          : true;
      },
      trimmedSelected() {
        return this.selected.name || this.selected;
      },
    },
    methods: {
      toggleDropdown() {
        this.open = !this.open;
      },
      openDropdown() {
        this.open = true;
      },
      closeDropdown() {
        this.open = false;
      },
      select(option) {
        if (typeof option == "string") {
          this.$emit("selected", option);
        } else {
          this.$emit("selected", option.name);
        }
        this.toggleDropdown();
      },
    },
    watch: {
      selected() {
        this.$emit("input", this.selected);
      },
      value() {
        this.selected = this.value;
      },
    },
  };
</script>
