<template>
  <div
    class="d-flex align-items-baseline justify-content-center rounded-pill bg-primary text-white py-2"
  >
    <div class="px-3" :class="{ clickable: value > 0 }" @click="removeQty()">
      -
    </div>

    {{ value }} {{ unit }}
    <div class="px-3 clickable" @click="addQty()">+</div>
  </div>
</template>
<script>
  export default {
    name: "Quantity",
    model: {
      prop: "value",
      event: "input",
    },
    props: {
      value: {
        type: Number,
        required: true,
        default: 0,
      },
      min: {
        type: Number,
        required: true,
      },
      max: {
        type: Number,
        required: false,
      },
      step: {
        type: Number,
        required: false,
        default: 1,
      },
      unit: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        quantity: this.value,
      };
    },
    methods: {
      addQty() {
        if (!this.max || this.value < this.max) {
          this.$emit("input", this.value + this.step);
        }
      },
      removeQty() {
        if (this.value > this.min) {
          this.$emit("input", this.value - this.step);
        }
      },
    },
  };
</script>
