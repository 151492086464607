<template lang="html">
  <div
    class="form-check czeds-checkbox clickable"
    :class="'czeds-checkbox-' + type"
    @click.self="triggerCheckBox()"
  >
    <input
      ref="input"
      :id="id"
      class="form-check-input clickable"
      type="checkbox"
      :name="name"
      :checked="isChecked"
      @input="updateInput()"
    />
    <label class="form-check-label clickable" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  model: {
    prop: "input",
    event: "update",
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    input: {
      type: Array,
      required: false,
    },
    value: {
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "small",
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      id: null,
      // check:this.checked
    };
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    isChecked() {
      return this.input.some(
        (checked) => JSON.stringify(checked) == JSON.stringify(this.value)
      );
    },
  },
  methods: {
    triggerCheckBox() {
      this.$refs.input.click();
    },
    updateInput() {
      let updated = [...this.input];
      if (this.isChecked) {
        updated.splice(updated.indexOf(this.value), 1);
      } else {
        updated.push(this.value);
      }
      this.$emit("update", updated);
    },
  },
};
</script>
