<template lang="html">
  <div
    v-bind:is="name"
    :height="height + 'px'"
    class="czeds-illustration"
  ></div>
</template>

<script>
  import Croissant from "@/assets/svgs/illustrations/croissant.svg";
  import Entree from "@/assets/svgs/illustrations/entree.svg";
  import Dessert from "@/assets/svgs/illustrations/dessert.svg";
  import Dish from "@/assets/svgs/illustrations/dish.svg";
  import Plate from "@/assets/svgs/illustrations/plate.svg";

  export default {
    name: "Illustrations",
    components: {
      Croissant,
      Dish,
      Entree,
      Dessert,
      Plate,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      height: {
        type: Number,
        required: false,
        default: 50,
      },
      // size: {
      //   type: String,
      //   required: false,
      //   default: 'small'
      // }
    },
    computed: {
      // height(){
      //   if(this.size == 'small'){
      //     return '18px';
      //   }else if(this.size == 'large'){
      //     return '26px';
      //   }else{
      //     return '50px';
      //   }
      // }
    },
  };
</script>
