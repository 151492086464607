<template lang="html">
  <div class="">
    <div class="mb-4 row" v-for="(item, index) in list" :key="'Checklist-'+index">
      <div class="col">
        <Checkbox v-model="checked" name="cshecklist" :value="item.name">
          {{item.name}}
        </Checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue'
export default {
  name: 'Checklist',
  components: {
    Checkbox
  },
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      checked: []
    }
  },

}
</script>
