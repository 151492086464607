<template>
  <div class="d-flex flex-wrap flex-row" v-if="Object.keys(recipe).length > 0">
    <div class="col-sm-8 pe-4 col-12">
      <div class="mb-5">
        <label for="title" class="form-label">
          {{ $t("recipe.form." + formType + ".title") }}
        </label>
        <input
          type="text"
          ref="title"
          name=""
          :placeholder="$t('recipe.form.' + formType + '.titlePlaceholder')"
          value=""
          class="form-control w-100 czeds-text-input"
          v-model="recipe.title"
          required
        />
      </div>
      <div class="mb-5">
        <label for="preparationTime" class="form-label">
          {{ $t("recipe.form." + formType + ".preparation") }}</label
        >
        <InputRange v-model="recipe.time">
          <template v-if="recipe.time < 60">
            <span>
              {{ $moment.duration(recipe.time, "minutes").format("m[min]") }}
            </span>
          </template>
          <template v-else>
            <span>{{
              $moment.duration(recipe.time, "minutes").format("h[h]mm")
            }}</span>
          </template>
        </InputRange>
      </div>
    </div>
    <div class="col-sm col-12 mb-5">
      {{ $t("recipe.form." + formType + ".category") }}
      <div class="row align-items-stretch justify-content-between">
        <div
          class="col-6 text-center mt-3"
          v-for="(category, index) in categories"
          :key="'category-' + index"
        >
          <Checkbox
            name="recipeCategories"
            v-model="recipe.categories"
            :value="category"
            type="large"
            :label="category"
          >
            <template v-if="categoriesIllustrations[category]">
              <Illustrations :name="categoriesIllustrations[category]" />
            </template>
          </Checkbox>
          <div class="mt-2 small">{{ $t("category." + category) }}</div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-5 text-center">
      <Button
        @click="sendForm()"
        :label="$t('recipe.form.' + formType + '.buttonLabel')"
      />
      <a class="d-block mt-3" href="" @click.prevent="$emit('cancel')">{{
        $t("recipe.form.edit.buttonCancelLabel")
      }}</a>
    </div>
  </div>
</template>

<script>
  import InputRange from "@/components/InputRange.vue";
  import CategoryService from "@/services/CategoryService";
  import Checkbox from "@/components/Checkbox.vue";
  import Button from "@/components/Button.vue";
  import Illustrations from "@/components/Illustration.vue";

  export default {
    name: "Step1",
    components: {
      InputRange,
      Checkbox,
      Button,
      Illustrations,
    },
    model: {
      prop: "recipe",
      event: "change",
    },
    props: {
      formType: {
        type: String,
        required: true,
        default: "create",
      },
      recipe: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        // formType: "edit",
        isFetching: false,
        categoriesIllustrations: {
          breakfast: "Croissant",
          dish: "Dish",
          entree: "Entree",
          dessert: "Dessert",
        },
        categories: {},
        recipeForm: this.recipe,
      };
    },
    mounted() {
      this.$refs.title.focus();
    },
    computed: {},
    methods: {
      cancel() {
        this.$emit("cancel");
      },
      checkInputs() {
        console.log("Should validate inputs here");
      },
      sendForm() {
        this.checkInputs();
        this.$emit("saved");
      },
    },
    beforeRouteEnter(to, from, next) {
      CategoryService.getAll()
        .then((response) => {
          next((vm) => {
            vm.categories = response.data;
          });
        })
        .catch((error) => {
          // TODO: Add alert
          console.log(error);
        });
    },
  };
</script>
