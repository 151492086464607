<template lang="html">
  <button
    type="button"
    name="button"
    :disabled="disabled"
    class="btn"
    :class="[buttonClass, 'btn-' + size, 'position-relative']"
    @click="
      {
        !loading ? $emit('click') : null;
      }
    "
  >
    <div
      :class="[
        'loader',
        'position-absolute',
        loading ? 'visible' : 'invisible',
      ]"
    >
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <div :class="{ invisible: loading }">{{ label }}</div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    size: {
      type: String,
      required: false,
      default: "large",
    },
    outline: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    buttonClass() {
      if (this.outline) {
        return "btn-outline-" + this.color;
      } else {
        return "btn-" + this.color;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  // height: 50px;
}

.dot {
  width: 5px;
  height: 5px;
  background: $primary;
  border-radius: 50%;
  margin: 0 5%;
  animation: grow 1.5s infinite;
  &:nth-child(2) {
    animation-delay: 0.5s;
  }
  &:nth-child(3) {
    animation-delay: 1s;
  }
}

@keyframes grow {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}
</style>
