<template>
  <div class="czeds-modal" :class="{ show: show }">
    <div class="czeds-modal-container text-center">
      <div class="d-flex align-items-center justify-content-end">
        <Icon
          name="close"
          size="large"
          class="clickable"
          @click.native="close()"
        />
      </div>
      <div class="h4">{{ title }}</div>
      <div class="mt-5">{{ body }}</div>
      <div class="row align-items-center justify-content-center mt-6">
        <div v-for="button in buttons" :key="button.id" class="col-auto">
          <Button
            :label="button.label"
            :color="button.color"
            @click.native="
              $emit('buttonClicked', { type: button.type, payload: payload })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Button from "@/components/Button.vue";
  import Icon from "@/components/Icon.vue";

  export default {
    name: "Modal",
    components: {
      Button,
      Icon,
    },
    props: {
      title: {
        type: String,
        default: function () {
          return this.$t("modal.doubleValidation.title");
        },
      },
      body: {
        type: String,
        required: false,
      },
      buttons: {
        type: Array,
        default: function () {
          return [
            {
              label: this.$t("modal.doubleValidation.cancel"),
              color: "outline-light",
              type: "secondary",
            },
            {
              label: this.$t("modal.doubleValidation.confirm"),
              color: "primary",
              type: "primary",
            },
          ];
        },
      },
    },
    data() {
      return {
        show: false,
        payload: null,
      };
    },
    methods: {
      open(payload) {
        this.payload = payload;
        this.show = true;
      },
      close() {
        this.show = false;
      },
    },
  };
</script>
