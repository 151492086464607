<template>
  <router-link
    class="czeds-TileRecipe text-decoration-none d-block bg-white rounded p-3"
    :to="{
      name: 'SingleRecipe',
      params: { id: recipe._id || recipe.id },
    }"
  >
    <div class="czeds-TileRecipe--asset rounded">
      <Icon class="ms-lg-2 ms-auto" name="heart" size="small" />
      <img src="/images/01.png" alt="" class="w-100" />
    </div>
    <div class="mt-4 czeds-TileRecipe--content">
      <div
        class="czeds-TileRecipe--content-title mb-2 fw-bolder text-uppercase"
      >
        {{ recipe.title }}
      </div>

      <div
        class="czeds-TileRecipe--content-informations d-flex align-items-center fs-6 mb-2"
      >
        <div class="col-auto">
          <template v-if="recipe.time < 60">
            <span>{{
              $moment.duration(recipe.time, "minutes").format("m [min]")
            }}</span>
          </template>
          <template v-else>
            <span>{{
              $moment.duration(recipe.time, "minutes").format("h[h]mm")
            }}</span>
          </template>
        </div>
        <template v-if="recipe.categories && recipe.categories.length > 0">
          <div
            class="col-auto"
            v-for="category in recipe.categories"
            :key="category"
          >
            {{ $t("category." + category) }}
          </div>
        </template>

        <!-- <div class="mx-2">&#183;</div> -->
      </div>
    </div>
  </router-link>
</template>

<script>
  import Icon from "@/components/Icon.vue";

  export default {
    name: "TileRecipe",
    components: {
      Icon,
    },
    props: {
      recipe: {
        type: Object,
        required: true,
      },
      refresh: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        categoriesIllustrations: {
          breakfast: "Croissant",
          lunch: "Sandwich",
          diner: "Hamburger",
          dessert: "IceCream",
        },
      };
    },
  };
</script>
