<template lang="html">
  <div class="">
    <div class="row banner-homepage align-items-center">
      <div class="col-auto ms-n7">
        <img :src="require('@/assets/images/homepage.png')" alt="My Image" />
      </div>
      <div class="col">
        <div class="h6 col-auto">{{ $t("weekly.heroSubtitle") }}</div>
        <div class="h1 col-auto">{{ $t("weekly.heroTitle") }}</div>
        <p>
          Welcome to your culinary dashboard, where you embark on a delicious
          journey through our handpicked recipes. Dive into this week's
          selection of mouthwatering dishes, or revisit your favorite recipes
          from previous weeks. Get ready to savor the flavors and discover
          culinary inspiration.
        </p>

        <router-link class="" :to="{ name: 'Recipes', params: {} }">
          <Button :label="$t('weekly.heroPrimaryButton')" size="large" />
        </router-link>
        <router-link class="" :to="{ name: 'NewRecipe' }">
          <Button
            class="ms-2"
            :label="$t('weekly.heroSecondaryButton')"
            size="large"
            color="secondary"
          />
        </router-link>
      </div>
    </div>
    <template>
      <div class="mt-n7 d-flex align-items-center justify-content-between">
        <div class="col">
          <div class="h6">{{ $t("weekly.subtitle") }}</div>
          <div class="h1">{{ $t("weekly.title") }}</div>
        </div>
        <div class="col-auto" v-if="recipes.length > 0">
          <Button
            :label="$t('weekly.regenerate')"
            size="large"
            @click="generateSelection()"
          />
        </div>
      </div>
      <div
        class="row align-items-stretch justify-content-start flex-nowrap py-6 overflow-hidden me-md-n7 me-n4"
        v-if="recipes.length > 0"
      >
        <div class="col-auto" v-for="(recipe, index) in recipes" :key="index">
          <TileRecipe :recipe="recipe" :refresh="true" />
        </div>
      </div>
      <div
        v-if="recipes.length == 0"
        class="col d-flex justify-content-start flex-column mt-5 px-3"
      >
        <div class="bg-light rounded p-4 text-center">
          <Illustration name="plate" :height="196"></Illustration>
          <div
            class="my-5 d-flex align-items-center justify-content-center gap-3"
          >
            <div
              class="bg-dark rounded-pill p-2 text-primary"
              tabindex="0"
              @click="removeNumber()"
            >
              <Icon class="clickable" name="minus" size="large" />
            </div>
            <span class="fs-1 fw-bold mx-4">{{ recipesNumber }}</span>
            <div
              tabindex="0"
              class="bg-dark rounded-pill p-2 text-primary"
              @click="addNumber()"
            >
              <Icon class="clickable" name="add" size="large" />
            </div>
          </div>
          <Button
            v-if="recipes.length == 0"
            :label="$t('weekly.generate')"
            size="small"
            class="col-auto"
            color="primary"
            @click="generateSelection()"
          ></Button>
        </div>
      </div>
    </template>
    <div class="d-flex align-items-center justify-content-between mb-6">
      <div class="col">
        <div class="h6">{{ $t("weekly.previousWeeks.subtitle") }}</div>
        <div class="h1">{{ $t("weekly.previousWeeks.title") }}</div>
      </div>
      <div class="col-4" v-if="recipes.length > 0">
        <Dropdown2
          v-model="selectedPreviousWeek"
          :options="formattedSelections"
          class="w-auto"
          @option-selected="getWeekSelection(selectedPreviousWeek.value)"
        />

        <!-- <Button label="previousWeek" @click="getPreviousWeeks(35)" /> -->
      </div>
    </div>
    <div class="row flex-wrap justify-content-start">
      <div
        class="col-6 mb-5"
        :key="index"
        v-for="(recipe, index) in previousWeeks"
      >
        <TileRecipe :recipe="recipe" />
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
  import TileRecipe from "@/components/TileRecipe.vue";
  import Dropdown2 from "@/components/Dropdown2.vue";
  import Button from "@/components/Button.vue";
  import SelectionService from "@/services/SelectionService";
  import Illustration from "@/components/Illustration";
  import Icon from "@/components/Icon";

  export default {
    name: "WeeklyRecipe",
    components: {
      TileRecipe,
      Button,
      Illustration,
      Icon,
      Dropdown2,
    },
    computed: {
      currentWeekMonday() {
        return this.$moment()
          .isoWeek(this.$route.params.weekNumber)
          .isoWeekday(1);
      },
      formattedSelections() {
        return this.allSelections.map((selection) => {
          return {
            name: this.$moment()
              .isoWeek(selection)
              .isoWeekday(1)
              .format("MMMM Do"),
            value: selection,
          };
        });
      },
    },
    data() {
      return {
        weekSelectionDate: undefined || this.moment().isoWeek(),
        recipes: [],
        recipesNumber: 5,
        previousWeeks: [],
        allSelections: [],
        selectedPreviousWeek: {
          name: this.moment().subtract(1, "weeks").format("MMMM Do"),
          value: this.moment().subtract(1, "weeks").isoWeek(),
        },
      };
    },
    methods: {
      removeNumber() {
        this.recipesNumber > 1 ? this.recipesNumber-- : null;
      },
      addNumber() {
        this.recipesNumber++;
      },
      generateSelection() {
        SelectionService.create({
          qty: this.recipesNumber,
          week: this.$route.params.weekNumber,
        })
          .then((response) => {
            // console.log(response);
            this.recipes = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getWeekSelection(weekNumber) {
        console.log(weekNumber);
        SelectionService.getWeekSelection(weekNumber)
          .then((response) => {
            this.previousWeeks = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    beforeRouteEnter(to, from, next) {
      // Define a variable to store the results of both API calls
      const data = {};

      // Use Promise.all to make both API calls simultaneously
      Promise.all([
        SelectionService.getWeekSelection(to.params.weekNumber),
        SelectionService.findAll(),
      ])
        .then(([weekSelectionResponse, allSelectionsResponse]) => {
          // Store the responses in the data variable
          data.weekSelection = weekSelectionResponse.data;
          data.allSelections = allSelectionsResponse.data;

          // Continue to the route
          next((vm) => {
            // Update the component's data with the results
            vm.recipes = data.weekSelection;
            vm.allSelections = data.allSelections;
            // vm.weekSelectionDate = data.weekSelection.weekNumber;
          });
        })
        .catch((error) => {
          // Handle errors here
          // TODO: Add alert or error handling
          console.error(error);
        });
    },
    mounted() {},
  };
</script>

<style lang="scss" scoped>
  .banner-homepage {
    // height: 489px;
    img {
      transform: translateY(-3vw);
      z-index: -1;
      max-width: 50vw;
      max-height: 45vw;
    }
  }
</style>
