<template lang="html">
  <div class="czeds-range">
    <div
      class="czeds-range--value bg-primary d-inline-block"
      :style="marginSize"
    >
      <slot />
    </div>
    <input
      type="range"
      :min="min"
      :max="max"
      :step="step"
      class="form-range"
      v-model.number="content"
      :style="backgroundStyle"
    />
  </div>
</template>

<script>
  export default {
    name: "InputRange",
    model: {
      prop: "value",
      event: "input",
    },
    props: {
      min: {
        type: Number,
        default: 5,
        required: false,
      },
      step: {
        type: Number,
        default: 5,
        required: false,
      },
      max: {
        type: Number,
        default: 240,
        required: false,
      },
      value: {
        type: Number,
        default: 5,
        required: false,
      },
    },
    data() {
      return {
        content: this.value,
      };
    },
    computed: {
      completion() {
        return ((this.content - this.min) / (this.max - this.min)) * 100;
      },
      backgroundStyle() {
        return (
          "background: linear-gradient(to right, #f8cd33, #f8cd33 " +
          this.completion +
          "%, #f8cd331a " +
          this.completion +
          "%, #f8cd331a 100%)"
        );
      },
      marginSize() {
        return "margin-left:" + this.completion + "%";
      },
    },
    watch: {
      content() {
        this.$emit("input", this.content);
      },
      value() {
        this.content = this.value;
      },
    },
  };
</script>
