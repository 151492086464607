<template>
  <router-view id="app" />
</template>
<script>
export default {
  name: "App",
  metaInfo: {
    meta: [
      { property: "og:url", content: window.location.href },
      {
        property: "twitter:image",
        content:
          window.location.protocol +
          "//" +
          window.location.host +
          "/metaImage.png",
      },
      {
        property: "og:image",
        content:
          window.location.protocol +
          "//" +
          window.location.host +
          "/metaImage.png",
      },
    ],
    link: [{ rel: "canonical", href: window.location.href }],
  },
};
</script>
